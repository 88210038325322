import React, { useState } from 'react';
import { Box, Flex, Text, Button, Badge, Title, Container, Space, SegmentedControl, Modal, LoadingOverlay } from '@mantine/core';
import { getPaddlePlanId } from '@/utils/CommonUtility';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePlanStore } from '@/stores/usePlanStore';
import { useUserStore } from '@/stores/useUserStore';
import { notifications } from '@mantine/notifications';
import { PlanService } from '@/services/PlanService';


interface IPlans {
  isOpen: boolean;
  primarySubscription: any;
  showTrialPlans: boolean;
  currentPlanId: string | undefined;
  disabledAccountMessage?: string;
  onClose: () => void;
  fetchSubscriptions: (workspaceId: string, organizationId: string) => void;
}


export const PlansModal = ({ isOpen, onClose, currentPlanId, primarySubscription, showTrialPlans, disabledAccountMessage, fetchSubscriptions }: IPlans) => {

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [plan, fetchPlan] = usePlanStore((state) => [state.plan, state.fetchPlan]);
  const [user] = useUserStore((state) => [state.user]);

  const [upgradeLoading, setUpgradeLoading] = useState(false);

  const monthlyPlans = [
    {
      title: 'Basic',
      trial_name: 'basic-trial', // trial name
      name: 'basic-monthly',
      price: '$19',
      period: '/mo',
      dev_paddle_id: '69597',
      qa_paddle_id: '69597',
      stage_paddle_id: '69597',
      prod_paddle_id: '563085',
      credits: [
        '10,000 clicks per month',
        '1 workspace',
        '5 bio links',
        '5 campaigns',
        '1 retargeting pixel'
      ],
      features: [
        'Unlimited links',
        'Call-to-actions (CTAs)',
        'UTM builder',
        'QR code generation',
        'Custom slug / Short link',
        'Bulk link shortening'
      ]
    },
    {
      title: 'Professional',
      trial_name: 'professional-trial',
      name: 'professional-monthly',
      price: '$49',
      period: '/mo',
      dev_paddle_id: '69596', // sandbox
      qa_paddle_id: '69596',
      stage_paddle_id: '69596',
      prod_paddle_id: '563086',
      credits: [
        '50,000 clicks per month',
        '5 workspaces',
        '25 bio links',
        'Unlimited campaigns',
        'Unlimited retargeting pixels'
      ],
      features: [
        'Everything in Basic, plus:',
        'Team access',
        'Branded link shortening',
        'Custom domains',
        'A/B testing',
        'Traffic routing / Link rotation',
        'Remove Replug Branding'
      ]
    },
    {
      title: 'Agency',
      trial_name: 'agency-trial',
      name: 'agency-monthly',
      price: '$79',
      period: '/mo',
      dev_paddle_id: '69598',
      qa_paddle_id: '69598',
      stage_paddle_id: '69598',
      prod_paddle_id: '563087',
      credits: [
        'Unlimited clicks per month',
        '10 workspaces',
        '50 bio links',
        'Unlimited campaigns',
        'Unlimited retargeting pixels'
      ],
      features: [
        'Everything in Professional, plus:',
        'Deep-linking',
        'White-label',
        'API access',
        'Dedicated support',
        'Onboarding & call support'
      ]
    }
  ];

  const annualPlans = [
    {
      title: 'Basic',
      name: 'basic-yearly',
      price: '$15',
      period: '/mo',
      dev_paddle_id: '69599',
      qa_paddle_id: '69599',
      stage_paddle_id: '69599',
      prod_paddle_id: '563088',
      credits: [
        '10,000 clicks per month',
        '1 workspace',
        '5 bio links',
        '5 campaigns',
        '1 retargeting pixel'
      ],
      features: [
        'Unlimited links',
        'Call-to-actions (CTAs)',
        'UTM builder',
        'QR code generation',
        'Custom slug / Short link',
        'Bulk link shortening'
      ]
    },
    {
      title: 'Professional',
      name: 'professional-yearly',
      price: '$39',
      period: '/mo',
      dev_paddle_id: '69601',
      qa_paddle_id: '69601',
      stage_paddle_id: '69601',
      prod_paddle_id: '563089',
      credits: [
        '50,000 clicks per month',
        '5 workspaces',
        '25 bio links',
        'Unlimited campaigns',
        'Unlimited retargeting pixels'
      ],
      features: [
        'Everything in Basic, plus:',
        'Team access',
        'Branded link shortening',
        'Custom domains',
        'A/B testing',
        'Traffic routing / Link rotation',
        'Remove Replug Branding'
      ]
    },
    {
      title: 'Agency',
      name: 'agency-yearly',
      price: '$63',
      period: '/mo',
      dev_paddle_id: '69600',
      qa_paddle_id: '69600',
      stage_paddle_id: '69600',
      prod_paddle_id: '563090',
      credits: [
        'Unlimited clicks per month',
        '10 workspaces',
        '50 bio links',
        'Unlimited campaigns',
        'Unlimited retargeting pixels'
      ],
      features: [
        'Everything in Professional, plus:',
        'Deep-linking',
        'White-label',
        'API access',
        'Dedicated support',
        'Onboarding & call support'
      ]
    }
  ];

  const [planType, setPlanType] = useState('monthly');
  const plans = showTrialPlans
    ? monthlyPlans : (planType === 'monthly' ? monthlyPlans : annualPlans)

  const isCurrentPlan = (subscriptionPlan: any) => {
    if (showTrialPlans) {
      return subscriptionPlan.trial_name == plan?.name
    }
    return currentPlanId == getPaddlePlanId(subscriptionPlan);
  }

  /**
   * Check if current usage exceeds the limits of the selected plan
   * @param workspace 
   * @param plan_name  selected plan name
   * @returns 
   */
  const hasExceededPlanLimits = async (workspace: any, plan_name: string) => {

    // check plan limits before upgrading
    return await new PlanService().checkPlanLimits(workspace._id, workspace.organization_id, plan_name).then((response) => {
      if (response.data?.status && response.data?.message && (response.data?.whitelabel_error || response.data?.is_exceeding_limits)) {
        notifications.show({
          color: 'red',
          message: response.data?.message,
        });
        return true;
      }
    }).catch((error) => {
      console.error('Error checking plan limits', error)
      return false;
    })

    return false;
  }


  const changeTrialPlan = async (plan: any) => {
    if (!defaultWorkspace || !user) {
      alert("Something went wrong. Please try again later.")
      return;
    }

    // check plan limits before upgrading
    if (await hasExceededPlanLimits(defaultWorkspace, plan.trial_name)) {
      setUpgradeLoading(false);
      return;
    }
    
    new PlanService().changeTrialPlan(defaultWorkspace?._id, defaultWorkspace?.organization_id, plan.trial_name).then((response) => {
      if (response.data?.status) {
        notifications.show({
          color: 'green',
          message: 'Trial plan has been changed successfully.',
        });
        fetchPlan(defaultWorkspace._id, defaultWorkspace.organization_id);
        onClose();
      }
    }).catch((error) => {
      console.error('Error changing trial plan', error)
    });
  }

  /**
   * Upgrade plan
   * @param plan 
   */
  const onPlanSelect = async (plan: any) => {
    if (showTrialPlans) {
      changeTrialPlan(plan);
      return;
    }


    const planId = getPaddlePlanId(plan);
    if (!defaultWorkspace || !user) {
      alert("Something went wrong. Please try again later.")
      return;
    }

    if (primarySubscription?.status == 'paused') {
      notifications.show({
        color: 'red',
        message: 'Subscription is paused. Please resume subscription to upgrade plan.',
      });
      return;
    }


    setUpgradeLoading(true);

    // check plan limits before upgrading
    if (await hasExceededPlanLimits(defaultWorkspace, plan.name)) {
      setUpgradeLoading(false);
      return false;
    }


    // upgrade plan incase of active subscription
    if (primarySubscription?.status == 'active') {
      await new PlanService().upgradePlan(defaultWorkspace._id, defaultWorkspace.organization_id, planId, primarySubscription.subscription_id).then((response) => {
        if (response.data?.status) {
          notifications.show({
            color: 'green',
            message: 'Plan has been upgraded successfully.',
          });
          setTimeout(() => {
            fetchPlan(defaultWorkspace._id, defaultWorkspace.organization_id);
            fetchSubscriptions(defaultWorkspace._id, defaultWorkspace.organization_id);
          }, 3000);
        } else {
          notifications.show({
            color: 'red',
            message: response.data?.message || 'Error upgrading plan. Please try again later.',
          });
        }
      }).catch((error) => {
        console.error('Error upgrading plan', error)
        notifications.show({
          color: 'red',
          message: error?.response?.data?.message || 'Error upgrading plan. Please try again later.',
        });
      });
      setUpgradeLoading(false);
      onClose();
      return;
    }

    // Upgrade plan for new subscription
    const passthrough = {
      organization_id: defaultWorkspace.organization_id,
      workspace_id: defaultWorkspace._id,
      user_id: user._id,
    };

    // @ts-ignore open paddle checkout
    Paddle.Checkout.open({
      product: planId,
      email: user?.email,
      disableLogout: true,
      coupon: plan?.name?.includes('yearly') ? '20OFFANNUAL' : '',
      passthrough: JSON.stringify(passthrough),
      successCallback: function (data: any) {
        console.log('Checkout complete', data);
        notifications.show({
          color: 'green',
          message: 'Plan has been upgraded successfully.',
        });
        setTimeout(() => {
          fetchPlan(defaultWorkspace._id, defaultWorkspace.organization_id);
          fetchSubscriptions(defaultWorkspace._id, defaultWorkspace.organization_id);
        }, 3000);
        onClose();
      },
      closeCallback: function () {
        console.log('Checkout closed');
        onClose();
      }
    });
    setUpgradeLoading(false);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size={1000}
      centered
    >
      <Container>
        <LoadingOverlay visible={upgradeLoading} />
        {disabledAccountMessage && (
          <Flex
            justify={'center'}
            align={'center'}
            mb={30}
          >
            <Text size="lg" ta="center" color="red" fw={500}>
              {disabledAccountMessage}
            </Text>
          </Flex>
        )}
        <Flex justify="center" align="center" mb="xl">
          {showTrialPlans ? (
            <Title order={2} ta="center">Trial Plans</Title>
          ) : (
            <SegmentedControl
              radius={1000}
              size="sm"
              color="primary"
              value={planType}
              onChange={setPlanType}
              data={[
                { label: 'Monthly', value: 'monthly' },
                { label: 'Annual (Save 20%)', value: 'annual' },
              ]}
            />
          )}
        </Flex>
        <Flex justify="space-between" align="stretch" wrap="wrap">
          {plans.map((plan, index) => (
            <Box key={index}>
              <Box style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem' }}>
                <Title order={2} ta="center">{plan.title}</Title>
                {showTrialPlans ? (
                  <Text ta="center" size="sm" fw={700} mt="md">14 days free trial</Text>
                ) : (
                  <Text ta="center" size="xl" fw={700} mt="md">{plan.price}<span>{plan.period}</span></Text>
                )}
                <ul>
                  {plan.credits.map((credit, index) => (
                    <li key={index}><Text size="sm">{credit}</Text></li>
                  ))}
                </ul>
                <Text fw="bold" ta="center" size="sm" mt="md">FEATURES</Text>
                <ul>
                  {plan.features.map((feature, index) => (
                    <li key={index}><Text size="sm">{feature}</Text></li>
                  ))}
                </ul>
                <Flex justify="center" mt="md">
                  {isCurrentPlan(plan) ? (
                    (primarySubscription?.status == 'deleted' || primarySubscription?.status == 'cancelled') ? (
                      <Button color='green' ml={'sm'} onClick={() => onPlanSelect(plan)}>
                        Renew Plan
                      </Button>
                    ) : (
                      <Badge color="green" variant="filled" size='lg'>Current Plan</Badge>
                    )) : (
                    <Button ml={'sm'} onClick={() => onPlanSelect(plan)}>
                      {showTrialPlans ? 'Start Trial' : 'Subscribe'}
                    </Button>
                  )}
                </Flex>
              </Box>
            </Box>
          ))}
        </Flex>
      </Container>
    </Modal>
  );
};
