import { Carousel } from '@mantine/carousel';
import { Anchor, Box, Button, Flex, Group, Image, Progress, Text, Title } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import { useRef } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import QuestionsImage from '../../assets/images/common/questions.svg';
import PartyLeft from '../../assets/images/common/party-left.png';
import PartyMiddle from '../../assets/images/common/party-middle.png';
import PartyRight from '../../assets/images/common/party-right.png';

import classes from './dashboard.module.css';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import ChromeImage from '../../assets/images/common/chrome.svg.png';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, formatLimit, getPlanLimitsProgressColor, getPlanLimitsProgressValue, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
import { usePlanStore } from '@/stores/usePlanStore';

export function DashboardInfoCarousel() {
  const autoplay = useRef(Autoplay({ delay: 5000 }));
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [plan, upgradeModalState, setUpgradeModalState, fetchPlan] = usePlanStore((state) => [state.plan, state.upgradeModalState, state.setUpgradeModalState, state.fetchPlan]);
  const [user] = useUserStore((state) => [state.user]);
  const navigate = useNavigate();

  return (
    <>
      <Carousel
        height={200}
        // plugins={[autoplay.current]}
        // onMouseEnter={autoplay.current.stop}
        // onMouseLeave={autoplay.current.reset}
        withIndicators
        withControls={false}
        classNames={{ indicator: classes.indicator }}
      >
        {defaultPermissions && checkPermission(defaultPermissions, ['view_whitelabel']) && !defaultWorkspace?.agency?.is_whitelabeled && <Carousel.Slide>
          <Flex >

            <Box>
              <Flex justify={'space-between'} align={'start'}  style={{
                position: 'absolute',
                top: 0,

              }}>
            <Image w={120} h={90} src={PartyLeft} />
            <Image w={220} h={50} src={PartyMiddle} />
            <Image w={120} h={90} src={PartyRight} />
            </Flex>
            <Flex style={{
              position: 'relative',
              zIndex: 9999 
            }} p={16} direction={'column'} >
              <Title c="primary" pb="sm" order={4}>
              October 2024 Product Updates
              </Title>
              <Text size="xs" pb="sm">
              Exciting news! We've just released an update packed with powerful features. Multiple domain support, enhanced bio-links, improved analytics, and much more.
              </Text>
              <a
                href="https://updates.replug.io/announcements/october-2024-enhanced-domain-management-platform-improvements"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }} // Ensuring no default underline
              >
                <Anchor mb={8} fw={700} size="sm">
                Discover What's new
                </Anchor>
              </a>
            </Flex>

            </Box>
          </Flex>
            
            
        </Carousel.Slide>}
        {!defaultWorkspace?.agency?.is_whitelabeled && <Carousel.Slide>
          <Flex p={16}>
            <Flex direction={'column'} pr={8}>
              <Title c="primary" pb="sm" order={4}>
                Chrome Extension
              </Title>
              <Text size="xs" pb="sm">
                {defaultWorkspace?.agency?.name} makes your work easy and efficient by its chrome extension.
              </Text>
              {/* <NavLink to={`/workspace/${defaultWorkspace?._id}/white-label`}>
                <Anchor mb={8} fw={700} size="sm">
                  Install Now
                </Anchor>
              </NavLink> */}
              <a
                href="https://chromewebstore.google.com/detail/replug-url-shortener-link/mofbjpedmdgclbmpgjoboblbppnmgpol?hl=en&pli=1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }} // Ensuring no default underline
              >
                <Anchor mb={8} fw={700} size="sm">
                  Install Now
                </Anchor>
              </a>
            </Flex>
            <Image src={ChromeImage} h={96} w={96} />
          </Flex>
        </Carousel.Slide>}
        {defaultPermissions && checkPermission(defaultPermissions, ['view_whitelabel']) && !defaultWorkspace?.agency?.is_whitelabeled && <Carousel.Slide>
          <Flex p={16}>
            <Flex direction={'column'} pr={8}>
              <Title c="primary" pb="sm" order={4}>
                Do you know?
              </Title>
              <Text size="xs" pb="sm">
                With our{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  'White-label'
                </span>{' '}
                feature, you can make {defaultWorkspace?.agency?.name} as your own brand. You can change the logo, favicon,
                and even the domain name.
              </Text>
              <NavLink to={`/workspace/${defaultWorkspace?._id}/white-label`}>
                <Anchor mb={8} fw={700} size="sm">
                  Let's try it out!
                </Anchor>
              </NavLink>
            </Flex>
            <Image w={120} h={140} src={QuestionsImage} />
          </Flex>
        </Carousel.Slide>}

        {defaultPermissions && checkPermission(defaultPermissions, ['view_plan']) && <Carousel.Slide>
          <Flex p={16} direction="column">
            <Title mb={8} c="primary" order={4}>
              Plan Info
            </Title>
            <Group grow mb={8}>
              <Text c="dimmed" fw={600} size="xs">
                Clicks
              </Text>
              <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'clicks')} color={getPlanLimitsProgressColor(plan, 'clicks')} />
              <Text c="dimmed" fw={600} size="xs">
                {plan?.used_limits?.clicks}/{formatLimit(plan?.limits?.clicks)}
              </Text>
            </Group>
            <Group grow mb={8}>
              <Text c="dimmed" fw={600} size="xs">
                Workspaces
              </Text>
              <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'workspaces')} color={getPlanLimitsProgressColor(plan, 'workspaces')} />
              <Text c="dimmed" fw={600} size="xs">
                {plan?.used_limits?.workspaces}/{formatLimit(plan?.limits?.workspaces)}
              </Text>
            </Group>
            <Group grow>
              <Text c="dimmed" fw={600} size="xs">
                Campaigns
              </Text>
              <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'campaigns')} color={getPlanLimitsProgressColor(plan, 'campaigns')} />
              <Text c="dimmed" fw={600} size="xs">
                {plan?.used_limits?.campaigns}/{formatLimit(plan?.limits?.campaigns)}
              </Text>
            </Group>
            <Button variant="subtle" mt={16} fw={700} size="sm" onClick={() => navigate('/account/billing')}>
              See all details
            </Button>
          </Flex>
        </Carousel.Slide>}
      </Carousel>
    </>
  );
}
