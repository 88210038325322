import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu, Modal, Group } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { format, subDays, subHours, subMonths, subYears, startOfMonth, endOfMonth, endOfDay, startOfDay } from 'date-fns';
import { useState } from 'react';

interface IDateMenu {
  onChange: (value: { label: string; value: string }) => void;
  labelValue?: string;
}

export function DateMenu({ onChange, labelValue = 'All time' }: IDateMenu) {
  const [label, setLabel] = useState(labelValue);
  const [isCustomDateOpen, setIsCustomDateOpen] = useState(false);
  const [customDateRange, setCustomDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const today = new Date();

  function formatDateRange(value: string) {
    let startDate, endDate;
    endDate = new Date();

    switch (value) {
      case 'last_24_hours':
        startDate = subHours(endDate, 24);
        break;
      case 'last_7_days':
        startDate = subDays(endDate, 7);
        break;
      case 'last_30_days':
        startDate = subMonths(endDate, 1);
        break;
      case 'last_90_days':
        startDate = subMonths(endDate, 3);
        break;
      case 'last_6_months':
        startDate = subMonths(endDate, 6);
        break;
      case 'last_1_year':
        startDate = subYears(endDate, 1);
        break;
      case 'this_month':
        startDate = startOfMonth(new Date());
        endDate = new Date();
        break;
      case 'last_month':
        startDate = startOfMonth(subMonths(new Date(), 1));
        endDate = endOfMonth(subMonths(new Date(), 1));
        break;
      case 'custom':
        if (customDateRange[0] && customDateRange[1]) {
          startDate = customDateRange[0];
          endDate = customDateRange[1];
        } else {
          return '';
        }
        break;
      default:
        return '';
    }

    return `${format(startDate, "yyyy-MM-dd'T'HH:mm:ss")}_${format(
      endDate,
      "yyyy-MM-dd'T'HH:mm:ss"
    )}`;
  }

  const handleCustomDateSelect = () => {
    if (customDateRange[0] && customDateRange[1]) {
      const startDate = startOfDay(customDateRange[0]);
      const endDate = endOfDay(customDateRange[1]);
      const formattedRange = `${format(startDate, "yyyy-MM-dd'T'HH:mm:ss")}_${format(
        endDate,
        "yyyy-MM-dd'T'HH:mm:ss"
      )}`;
      setLabel(`${format(startDate, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`);
      onChange({ label: 'Custom', value: formattedRange });
      setIsCustomDateOpen(false);
    }
  };

  return (
    <>
      <Menu>
        <Menu.Target>
          <Button
            variant="outline"
            color="gray"
            size="xs"
            rightSection={<FontAwesomeIcon icon={faChevronDown} />}
          >
            {label}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {[
            { label: 'All time', value: '' },
            { label: 'Last 24 hours', value: 'last_24_hours' },
            { label: 'Last 7 days', value: 'last_7_days' },
            { label: 'Last 30 days', value: 'last_30_days' },
            { label: 'Last 90 days', value: 'last_90_days' },
            { label: 'Last 6 months', value: 'last_6_months' },
            { label: 'Last 1 year', value: 'last_1_year' },
            { label: 'This month', value: 'this_month' },
            { label: 'Last month', value: 'last_month' },
          ].map((item) => (
            <Menu.Item
              key={item.label}
              onClick={() => {
                setLabel(item.label);
                onChange({ label: item.label, value: formatDateRange(item.value) });
              }}
            >
              {item.label}
            </Menu.Item>
          ))}
          <Menu.Item onClick={() => setIsCustomDateOpen(true)}>Custom range</Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <Modal
        opened={isCustomDateOpen}
        onClose={() => setIsCustomDateOpen(false)}
        title="Select Custom Date Range"
      >
        <DatePickerInput
          type="range"
          label="Date range"
          placeholder="Pick dates range"
          value={customDateRange}
          onChange={setCustomDateRange}
          maxDate={endOfDay(new Date())}
          renderDay={(date) => {
            const day = date.getDate();
            const isToday = date.toDateString() === today.toDateString();
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  backgroundColor: isToday ? '#003aa9' : 'transparent',
                  color: isToday ? 'white' : 'inherit',
                }}
              >
                {day}
              </div>
            );
          }}
        />
        <Group mt="md">
          <Button onClick={handleCustomDateSelect}>Apply</Button>
        </Group>
      </Modal>
    </>
  );
}